//fontawesome
(function () {
  var css = document.createElement("link");
  css.href = "https://use.fontawesome.com/releases/v5.1.0/css/all.css";
  css.rel = "stylesheet";
  css.type = "text/css";
  document.getElementsByTagName("head")[0].appendChild(css);
})();

//YakuHanJP
(function () {
  var css = document.createElement("link");
  css.href =
    "https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/css/yakuhanjp.min.css";
  css.rel = "stylesheet";
  css.type = "text/css";
  document.getElementsByTagName("head")[0].appendChild(css);
})();

//googlefont読み込み
var WebFont = require('webfontloader');
WebFont.load({
  google: {
    families: ['Roboto:400,700', 'Noto Sans JP:500,700']
  }
});

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

!(function () {
  const viewport = document.querySelector('meta[name="viewport"]');
  function switchViewport() {
    const value =
      window.outerWidth > 360
        ? 'width=device-width,initial-scale=1'
        : 'width=360';
    if (viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value);
    }
  }
  addEventListener('resize', switchViewport, false);
  switchViewport();
})();

//スムーススクロール
import SmoothScroll from 'smooth-scroll'
new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]',
  speed: 100
});

//Vigest+ XML 読み込み
(function() {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const XML = `${ protocol }//${ host }/digestplus/rss.xml`;
  const listElement = document.querySelector('.c-vrdigest > .container > .c-vrdigest__slider > .c-vrdigest__slider--container > .swiper-wrapper');
  function convertToISODate(dateString) {
    const parts = dateString.split('.');
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    const daysInFebruary = isLeapYear ? 29 : 28;
    if (month === 2 && day > daysInFebruary) {
        return 'Invalid date';
    }
    const isoDate = new Date(Date.UTC(year, month - 1, day)).toISOString();
    return isoDate;
  }
  if (!listElement) return;
  fetch(XML, {
    mode: 'cors',
    })
    .then(response => response.text())
    .then(xml => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(xml, 'text/xml');
      const items = doc.querySelectorAll('item');
      const displayNumber = '5';
      for (let i = 0; i < displayNumber && i < items.length; i++) {
        const item = items[i];
        const itemTitle = item.querySelector('title').textContent;
        const itemUrl = item.querySelector('link').textContent;
        const itemDescription = item.querySelector('description').textContent;
        const itemPubDate = item.querySelector('pubDate').textContent;
        const itemDate = new Date(convertToISODate(itemPubDate)).toLocaleDateString('ja-JP', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const itemCategory = item.querySelector('category').textContent;
        const categoryClass = {
          'テレビ': '-tv',
          'メディア': '-media',
          '広告・マーケティング': '-ad',
          '生活者データ': '-market',
        };
        const itemClassName = categoryClass[itemCategory];
        const itemThumbnail = item.querySelector('thumbnail').getAttribute('url');
        const articleElement = document.createElement('div');
        articleElement.classList.add('swiper-slide');
        articleElement.innerHTML = `
          <div class="c-vrdigest__item">
              <a href='${itemUrl}'>
              <div class="c-vrdigest__item--inner">
                  <figure><img src="${itemThumbnail}" alt="${itemTitle}"></figure>
                  <div class="c-vrdigest__txt">
                  <p class="c-vrdigest__tag ${itemClassName}">${itemCategory}</p>
                  <p class="c-vrdigest__excerpt">${itemTitle}</p>
                  <time class="c-vrdigest__date">${itemDate}</time>
                  </div>
              </div>
              </a>
          </div>
        `;
        listElement.appendChild(articleElement);
      }
      var slider_t_vr = new Swiper('.c-vrdigest__slider--container', {
        spaceBetween: 0,
        loop: true,
        loopAdditionalSlides: 1,
        spaceBetween: 20, // スライド間の余白サイズ(ピクセル)
        navigation: {
          nextEl: '.c-vrdigest__slider--swiper-button-next',
          prevEl: '.c-vrdigest__slider--swiper-button-prev',
        },
        pagination: {
          el: '.c-vrdigest__slider--swiper-pagination',
          clickable: true //この行を追記する
        },
      });
    });
})();

//スライダー
import Swiper from 'swiper';

//サムネイル
var slider_t_kv_thumb = new Swiper('.t-kv__thumb--container', {
  spaceBetween: 0,
  loop: true,
  loopAdditionalSlides: 1,
  spaceBetween: 20, // スライド間の余白サイズ(ピクセル)
  freeMode: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  navigation: {
    nextEl: '.t-kv__thumb--swiper-button-next',
    prevEl: '.t-kv__thumb--swiper-button-prev',
  },
});

var slider_t_kv = new Swiper('.t-kv__slider--container', {
  spaceBetween: 0,
  loop: true,
  thumbs: {
    swiper: slider_t_kv_thumb
  },
  autoplay: {
      delay: 3500,
  },
  navigation: {
    nextEl: '.t-kv__slider--swiper-button-next',
    prevEl: '.t-kv__slider--swiper-button-prev',
  },
  pagination: {
    el: '.t-kv__slider--swiper-pagination',
    clickable: true //この行を追記する
  },

});

import Rellax from 'Rellax';
var rellax = new Rellax('.t-town__inner', {
  center: true,
});

if (document.getElementsByClassName('l-header').length) {
  function gnavTop() {
    const header_height = document.getElementsByTagName('header')[0].clientHeight;
    const gnav = document.getElementById('modal-1');
    const wrapper = document.getElementById('wrapper');
    gnav.style.top = header_height + 'px';
    wrapper.style.paddingTop = header_height + 'px';
  }

  window.addEventListener('resize', gnavTop);
  window.addEventListener('DOMContentLoaded', gnavTop);
}

//トップページローデイング画面
if (document.getElementsByClassName('t-loading').length) {
  function topLoading() {
    const bodyElement = document.querySelector('body');
    bodyElement.classList.add('loaded');
    setTimeout(() => {
      bodyElement.classList.add('loaded_2nd');
    }, 1000)
  }
  window.addEventListener('DOMContentLoaded', topLoading);
}

//下層ページタイトル上イラスト
if (document.getElementsByClassName('l-kv__illust').length) {
  function lowerKv() {
    const header_height = document.getElementsByTagName('header')[0].clientHeight;
    const lowerKvIllust = document.getElementById('l-kv__illust');
    const lowerKvInner_height = document.getElementById('l-kv__inner').clientHeight;
    const main = document.getElementById('main');
    lowerKvIllust.style.height = header_height + 'px';
    main.style.paddingTop = lowerKvInner_height + 'px';
  }

  window.addEventListener('resize', lowerKv);
  window.addEventListener('DOMContentLoaded', lowerKv);
}

if (document.getElementsByClassName('l-footer').length) {
  const pagetopBtn = document.querySelector('#pagetop');
  window.addEventListener('scroll', () => {
    const docHeight = document.body.clientHeight;
    const scrollCount = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollPos = document.documentElement.clientHeight + scrollCount;
    const footerHeight = document.querySelector('footer').clientHeight;
    if (scrollCount <= 100) {
      pagetopBtn.style.opacity = '0';
      pagetopBtn.style.visibility = 'hidden';
    } else {
      pagetopBtn.style.opacity = '1';
      pagetopBtn.style.visibility = 'visible';
    }
    if (docHeight - scrollPos <= footerHeight) {
      pagetopBtn.style.position = 'absolute';
      pagetopBtn.style.top = 0;
      pagetopBtn.style.bottom = 'auto';
      pagetopBtn.style.transform = 'translateY(-50%)';
    } else {
      pagetopBtn.style.position = 'fixed';
      pagetopBtn.style.top = 'auto';
      pagetopBtn.style.bottom = 20 + 'px';
      pagetopBtn.style.transform = 'none';
    }
  });
}

import MicroModal from 'microModal';
window.addEventListener('DOMContentLoaded', () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  const btn = document.querySelector('#js-buttonHamburger');

  // ページ内リンクスムーススクロール
  anchorLinksArr.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetoffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;

      if (btn.ariaExpanded == 'true') {
        btn.ariaExpanded = false;
      }
      // micromodalによるclose
      anchorLinksArr.ariaExpanded = false;
      //MicroModal.close('modal-1');

      window.scrollTo({
        top: targetoffsetTop,
        behavior: 'smooth',
      });
    });
  });

  let scrollPos;

  // メニューの開閉
  btn.addEventListener('click', function () {
    const bodyElement = document.querySelector('body');
    if (btn.ariaExpanded == 'false') {
      btn.ariaExpanded = true;

      scrollPos = window.scrollY;
      bodyElement.classList.add('open');
      bodyElement.style.top = scrollPos;

      MicroModal.show('modal-1', {
        disableScroll: true, // ページスクロールを無効に
        awaitOpenAnimation: true, // 開閉時のアニメーションを可能に
      });
    } else {
      btn.ariaExpanded = false;
      bodyElement.classList.remove('open');
      window.scrollTo(0, scrollPos);

      MicroModal.close('modal-1', {
        awaitCloseAnimation: true,
      });
    }
  });
});

window.addEventListener('DOMContentLoaded', () => {
  const closeBtn = document.getElementsByClassName('fixbnr-serviceShindan__close');
  const fixbnrServiceShindan = document.getElementsByClassName('fixbnr-serviceShindan');
  for (let i = 0; i < closeBtn.length; i++) {
    closeBtn[i].addEventListener('click', function () {
      fixbnrServiceShindan[i].classList.add('-hide');
    });
  }
});

/* =================================================== */
// slideUp, slideDown, slideToggle関数を定義
/* =================================================== */

// 要素をスライドしながら非表示にする関数(jQueryのslideUpと同じ)
const slideUp = (el, duration = 300) => {
  el.style.height = el.offsetHeight + "px";
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  setTimeout(() => {
    el.style.display = "none";
    el.style.removeProperty("height");
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
    el.classList.remove("is-open");
  }, duration);
};

// 要素をスライドしながら表示する関数(jQueryのslideDownと同じ)
const slideDown = (el, duration = 300) => {
  el.classList.add("is-open");
  el.style.removeProperty("display");
  let display = window.getComputedStyle(el).display;
  if (display === "none") {
    display = "block";
  }
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.height = height + "px";
  el.style.removeProperty("padding-top");
  el.style.removeProperty("padding-bottom");
  el.style.removeProperty("margin-top");
  el.style.removeProperty("margin-bottom");
  setTimeout(() => {
    el.style.removeProperty("height");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
  }, duration);
};

// 要素をスライドしながら交互に表示/非表示にする関数(jQueryのslideToggleと同じ)
const slideToggle = (el, duration = 300) => {
  if (window.getComputedStyle(el).display === "none") {
    return slideDown(el, duration);
  } else {
    return slideUp(el, duration);
  }
};

/* =================================================== */
// DOM操作
/* =================================================== */

// アコーディオンを全て取得
const accordions = document.querySelectorAll(".js-accordion");
// 取得したアコーディオンをArrayに変換(IE対策)
const accordionsArr = Array.prototype.slice.call(accordions);

accordionsArr.forEach((accordion) => {
  // Triggerを全て取得
  const accordionTriggers = accordion.querySelectorAll(".js-accordion-trigger");
  // TriggerをArrayに変換(IE対策)
  const accordionTriggersArr = Array.prototype.slice.call(accordionTriggers);

  accordionTriggersArr.forEach((trigger) => {
    // Triggerにクリックイベントを付与
    trigger.addEventListener("click", () => {
      // '.is-active'クラスを付与or削除
      trigger.classList.toggle("is-active");
      trigger.parentNode.classList.toggle("is-active");
      // 開閉させる要素を取得
      const content = trigger.parentNode.querySelector(".js-accordion-content");
      // 要素を展開or閉じる
      // console.log({content});
      slideToggle(content);
    });
  });
});

const searchOverlay = document.querySelectorAll('.l-header__searchbox--overlay');
const searchBox = document.querySelectorAll('.l-header__searchbox');

// 正しい例
for (let i = 0; i < 2; i++) {
  searchOverlay[i].addEventListener('click', () => {
    searchBox[i].style.display = 'none';
  });
}

document.addEventListener('DOMContentLoaded', function () {
  // タブに対してクリックイベントを適用
  const tabs = document.getElementsByClassName('js-tab');
  for (let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener('click', tabSwitch, false);
  }

  // タブをクリックすると実行する関数
  function tabSwitch() {
    // タブのclassの値を変更
    document.getElementsByClassName('is-active')[0].classList.remove('is-active');
    this.classList.add('is-active');
    // コンテンツのclassの値を変更
    document.getElementsByClassName('is-show')[0].classList.remove('is-show');
    const arrayTabs = Array.prototype.slice.call(tabs);
    const index = arrayTabs.indexOf(this);
    document.getElementsByClassName('js-tab_content')[index].classList.add('is-show');
  };
}, false);

const q2 = document.getElementById('js-q2');
const q3 = document.getElementById('js-q3');
const result = document.getElementById('js-result');
const q1Label = document.getElementsByClassName('js-q1Label');
const q2Label = document.getElementsByClassName('js-q2Label');
const q3Label = document.getElementsByClassName('js-q3Label');

function q2Show() {
  q2.classList.add('is-show');
  const rect = q2.getBoundingClientRect().top;
  const offset = window.pageYOffset;
  const gap = 60;
  const target = rect + offset - gap;
  window.scrollTo({
    top: target,
    behavior: 'smooth',
  });
}
for (let i = 0; i < q1Label.length; i++) {
  q1Label[i].addEventListener('click', q2Show, false);
}

function q3Show() {
  q3.classList.add('is-show');
  const rect = q3.getBoundingClientRect().top;
  const offset = window.pageYOffset;
  const gap = 60;
  const target = rect + offset - gap;
  window.scrollTo({
    top: target,
    behavior: 'smooth',
  });
}
for (let i = 0; i < q2Label.length; i++) {
  q2Label[i].addEventListener('click', q3Show, false);
}

function resultShow() {
  result.classList.add('is-show');
  const rect = result.getBoundingClientRect().top;
  const offset = window.pageYOffset;
  const gap = 60;
  const target = rect + offset - gap;
  window.scrollTo({
    top: target,
    behavior: 'smooth',
  });
}
for (let i = 0; i < q3Label.length; i++) {
  q3Label[i].addEventListener('click', resultShow, false);
}

/* =================================================== */
// 「サービス一覧」ページ >> URLのパラメータによる切り替え
/* =================================================== */

var param = location.search;
if(param){//パラメータが付与されている場合

  if (document.getElementsByClassName('service-wrapper').length) {

    const tab = document.getElementsByClassName('js-tab');
    for (let i = 0; i < tab.length; i++) {
      tab[i].classList.remove("is-active");//「データから探す」「マーケティング課題から探す」両タブを非アクティブに
    }
    const content = document.getElementsByClassName('js-tab_content');
    for (let i = 0; i < content.length; i++) {
      content[i].classList.remove("is-show");//「データから探す」「マーケティング課題から探す」両コンテンツを非アクティブに
    }

    const nav = document.getElementsByClassName('service-nav');
    for (let i = 0; i < nav.length; i++) {
      const mediaAllNav = nav[0].children;
      const marketingAllNav = nav[1].children;
      mediaAllNav[i].classList.remove("is-active");//「データから探す」内全選択肢を非アクティブに
      marketingAllNav[i].classList.remove("is-active");//「マーケティング課題から探す」内全選択肢を非アクティブに
    }

    function mediaShow() {//「データから探す」をアクティブに
      const content_media = document.getElementsByClassName('-media');
      for (let i = 0; i < content_media.length; i++) {
        content_media[1].classList.add("is-show");//タブ
        content_media[0].classList.add("is-active");//コンテンツ
      }
    }

    function marketingShow() {//「マーケティング課題から探す」をアクティブに
      const content_marketing = document.getElementsByClassName('-marketing');
      for (let i = 0; i < content_marketing.length; i++) {
        content_marketing[1].classList.add("is-show");//タブ
        content_marketing[0].classList.add("is-active");//コンテンツ
      }
    }

    function mediaNavSwitch(num) {//「データから探す」内の指定した番号をアクティブに
      for (let i = 0; i < nav.length; i++) {
        const mediaNav = nav[0].children;
        console.log(mediaNav[num]);
        mediaNav[num].classList.add("is-active");
      }
    }

    function marketingNavSwitch(num) {//「マーケティング課題から探す」内の指定した番号をアクティブに
      for (let i = 0; i < nav.length; i++) {
        const mediaNav = nav[1].children;
        console.log(mediaNav[num]);
        mediaNav[num].classList.add("is-active");
      }
    }

    if(param.includes('tv')){ mediaShow(); mediaNavSwitch(1);}
    if(param.includes('internet')){ mediaShow(); mediaNavSwitch(2);}
    if(param.includes('consumer_data')){ mediaShow(); mediaNavSwitch(3);}
    if(param.includes('radio')){ mediaShow(); mediaNavSwitch(4);}
    if(param.includes('newspaper')){ mediaShow(); mediaNavSwitch(5);}
    if(param.includes('magazine')){ mediaShow(); mediaNavSwitch(6);}
    if(param.includes('traffic_outdoor')){ mediaShow(); mediaNavSwitch(7);}
    if(param.includes('other')){ mediaShow(); mediaNavSwitch(8);}

    if(param.includes('theme')){ marketingShow(); marketingNavSwitch(0);}
    if(param.includes('market')){ marketingShow(); marketingNavSwitch(1);}
    if(param.includes('target')){ marketingShow(); marketingNavSwitch(2);}
    if(param.includes('aim_setting')){ marketingShow(); marketingNavSwitch(3);}
    if(param.includes('totalsupport')){ marketingShow(); marketingNavSwitch(4);}
    if(param.includes('creative_planning')){ marketingShow(); marketingNavSwitch(5);}
    if(param.includes('media_planning')){ marketingShow(); marketingNavSwitch(6);}
    if(param.includes('effect_measurement')){ marketingShow(); marketingNavSwitch(7);}

  }
}

//ヘッダー追従関数（上スクロール時、ページ最下部到達時のみ表示）
function headerScrollFunc() {
  'use strict';

  //追従ヘッダー取得
  const header = document.getElementsByTagName('header')[0];

  //初期設定
  const options = {
    fixClass: 'is-headerFix',  //追従class
    hideClass: 'is-headerHide',  //追従中 非表示class
    showClass: 'is-headerShow',  //追従中 表示class
    showDelay: 500 //表示するまでのディレイ時間（指定秒数（ミリ秒）以上、下スクロールしなければ表示）
  };

  //ヘッダーが存在するかチェック
  if(!header) {
    return false;
  }

  //スクロール開始位置
  options.startPosi = 0;
  //上スクロール判別フラグ
  options.upFlg = false;
  //表示タイマー用設定
  options.setTimeoutFlg = false;
  options.setTimeoutId = 0;

  //ヘッダーfix関数呼び出し
  window.addEventListener('resize', function() {
    headerFix(options);
  });
  window.addEventListener('scroll', function() {
    headerFix(options);
  });

  //追従設定
  function headerFix(options) {
    //追従前のヘッダー位置（ヘッダーの位置によって色々パターンがあると思います）
    const headerBasePosi = 0;

    /*
      ヘッダーを追従させる位置
        画面の高さ、特定の要素の位置から追従開始など、色々パターンがあると思います。
        今回はヘッダーの高さを超えたら追従処理を開始
    */
    const headerFixPosi = header.clientHeight;

    //スクロール量（ウィンドウの上端）取得
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    //class設定
    const fixClass = options.fixClass;
    const showClass =options.showClass;
    const hideClass =options.hideClass;

    //上下スクロール判別
    //下スクロール（新規取得したスクロール量がoptions.startPosiを超えている）
    if (scrollTop > options.startPosi) {
      //上スクロール判別フラグを無効化
      options.upFlg = false;

      //表示タイマーリセット
      clearTimeout(options.setTimeoutId);
      options.setTimeoutFlg = false;

      //スクロール量がヘッダーを追従させる位置を超えているか
      if(scrollTop > headerFixPosi) {
        //追従class追加
        header.classList.add(fixClass);
      }

      //ページの高さ取得
      const pageHeight = document.documentElement.scrollHeight;
      //下スクロール位置取得
      const scrollBottom = window.innerHeight + scrollTop;
      //ページの最下部だった場合はヘッダーを表示
      if (pageHeight <= scrollBottom) {
        headerShow(hideClass,showClass);

      //ページの途中だった場合
      } else {
        //表示されている場合ヘッダーを非表示
        if(header.classList.contains(showClass)) {
          headerHide(hideClass,showClass);
        }
      }

    //上スクロール（新規取得したスクロール量がoptions.startPosi以下）
    } else {
      //上スクロール判別フラグを有効化
      options.upFlg = true;

      //ヘッダーが追従前の位置に来ている場合は追従を終了する
      if(scrollTop <= headerBasePosi) {
        //全ての追従用classを削除
        header.classList.remove(fixClass,showClass,hideClass);

      //ヘッダーが追従前の位置を超えている場合
      } else {
        //表示タイマーが設定されていなければタイマーを設定
        if(!options.setTimeoutFlg) {
          //表示タイマーセット
          options.setTimeoutFlg = true;
          //指定秒数以上、下スクロールしていない場合のみヘッダーを表示
          options.setTimeoutId = setTimeout(function() {
            if(options.upFlg && header.classList.contains(fixClass)) {
              headerShow(hideClass,showClass);
            }
          }, options.showDelay);
        }
      }
    }

    //スクロール開始位置を更新
    options.startPosi = scrollTop;
  }

  //追従ヘッダー表示関数
  function headerShow(hideClass,showClass) {
    header.classList.remove(hideClass);
    header.classList.add(showClass);
  }

  //追従ヘッダー非表示関数
  function headerHide(hideClass,showClass) {
    header.classList.remove(showClass);
    header.classList.add(hideClass);
  }
}

headerScrollFunc();

/* =================================================== */
// 「資料ダウンロード」ページ
/* =================================================== */

//ページナビゲーションfix処理
function material_headerScrollFunc() {

  'use strict';

  //追従ヘッダー取得
  const material_header = document.getElementById('js--material-nav');
  document.getElementById('js--material-body').style.paddingTop = material_header.clientHeight + 'px';

  //初期設定
  const options = {
    fixClass: 'is-headerFix',  //追従class
    hideClass: 'is-headerHide',  //追従中 非表示class
    showClass: 'is-headerShow',  //追従中 表示class
    showDelay: 500 //表示するまでのディレイ時間（指定秒数（ミリ秒）以上、下スクロールしなければ表示）
  };

  //ヘッダーが存在するかチェック
  if(!material_header) {
    return false;
  }

  //スクロール開始位置
  options.startPosi = 0;
  //上スクロール判別フラグ
  options.upFlg = false;
  //表示タイマー用設定
  options.setTimeoutFlg = false;
  options.setTimeoutId = 0;


  //ヘッダーfix関数呼び出し
  window.addEventListener('resize', function() {
    material_headerFix(options);
  });
  window.addEventListener('scroll', function() {
    material_headerFix(options);
  });

  //追従設定
  function material_headerFix(options) {
    //追従前のヘッダー位置（ヘッダーの位置によって色々パターンがあると思います）
    const material_headerBasePosi = 270;

    /*
      ヘッダーを追従させる位置
        画面の高さ、特定の要素の位置から追従開始など、色々パターンがあると思います。
        今回はヘッダーの高さを超えたら追従処理を開始
    */
    const material_headerFixPosi = material_header.clientHeight + 270;


    //スクロール量（ウィンドウの上端）取得
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    //class設定
    const fixClass = options.fixClass;
    const showClass =options.showClass;
    const hideClass =options.hideClass;

    //上下スクロール判別
    //下スクロール（新規取得したスクロール量がoptions.startPosiを超えている）
    if (scrollTop > options.startPosi) {
      //上スクロール判別フラグを無効化
      options.upFlg = false;

      //表示タイマーリセット
      clearTimeout(options.setTimeoutId);
      options.setTimeoutFlg = false;

      //スクロール量がヘッダーを追従させる位置を超えているか
      if(scrollTop > material_headerFixPosi) {
        //追従class追加
        material_header.classList.add(fixClass);
      }

      //ページの高さ取得
      const pageHeight = document.documentElement.scrollHeight;
      //下スクロール位置取得
      const scrollBottom = window.innerHeight + scrollTop;
      //ページの最下部だった場合はヘッダーを表示
      if (pageHeight <= scrollBottom) {
        material_headerShow(hideClass,showClass);

      //ページの途中だった場合
      } else {
        //表示されている場合ヘッダーを非表示
        if(material_header.classList.contains(showClass)) {
          material_headerHide(hideClass,showClass);
        }
      }


    //上スクロール（新規取得したスクロール量がoptions.startPosi以下）
    } else {
      //上スクロール判別フラグを有効化
      options.upFlg = true;

      //ヘッダーが追従前の位置に来ている場合は追従を終了する
      if(scrollTop <= material_headerBasePosi) {
        //全ての追従用classを削除
        material_header.classList.remove(fixClass,showClass,hideClass);

      //ヘッダーが追従前の位置を超えている場合
      } else {
        //表示タイマーが設定されていなければタイマーを設定
        if(!options.setTimeoutFlg) {
          //表示タイマーセット
          options.setTimeoutFlg = true;
          //指定秒数以上、下スクロールしていない場合のみヘッダーを表示
          options.setTimeoutId = setTimeout(function() {
            if(options.upFlg && material_header.classList.contains(fixClass)) {
              material_headerShow(hideClass,showClass);
            }
          }, options.showDelay);
        }
      }
    }

    //スクロール開始位置を更新
    options.startPosi = scrollTop;
  }

  //追従ヘッダー表示関数
  function material_headerShow(hideClass,showClass) {
    material_header.classList.remove(hideClass);
    material_header.classList.add(showClass);
  }

  //追従ヘッダー非表示関数
  function material_headerHide(hideClass,showClass) {
    material_header.classList.remove(showClass);
    material_header.classList.add(hideClass);
  }
}
function l_headerNofix(){
  const l_header = document.getElementsByClassName('l-header');
  for (let i = 0; i < l_header.length; i++) {
    l_header[i].classList.add('-nofix');
  }
}

if (document.getElementsByClassName('material').length) {
  material_headerScrollFunc();
  l_headerNofix();
}

//ページナビゲーション内のボタン押下時の処理
const trigger = document.getElementsByClassName('js-trigger');//"js-trigger"が付与された要素
for (let i = 0; i < trigger.length; i++) {
  trigger[i].addEventListener('click', function () {
    const trigger_name = trigger[i].dataset.trigger;//クリックした要素のdate-trigger値

    if(trigger_name == "accordion"){//date-trigger値がaccordionの時
      const section_name = trigger[i].hash.slice(1);//hashの値

      const target_section = document.getElementById(section_name);//hashの値と合致するセクション

      const accWrap=target_section.getElementsByClassName("js-accordion");
      const accTrigger=target_section.getElementsByClassName("js-accordion-trigger");
      const accContent=target_section.querySelectorAll(".js-accordion-content");
      for (let i = 0; i < accWrap.length; i++) {
        accWrap[i].classList.add("is-active");
        accTrigger[i].classList.add("is-active");
        slideDown(accContent[i]);
      }
    }

    if(trigger_name == "tag"){//date-trigger値がtagの時
      const tag_name = trigger[i].dataset.section;
      const target_tag = document.getElementById("tags_"+tag_name);
      slideToggle(target_tag);
      trigger[i].classList.toggle("is-active");

      // console.log(tag_name);
    }

  });
}

const anchorlink = document.querySelectorAll('.material-nav__section a');//「資料ダウンロード」ページ > ページ内ナビゲーションボタン
for (let i = 0; i < anchorlink.length; i++) {
	if( anchorlink[i].hasAttribute("href") ) {//ボタンがhref属性を持ってる場合
    anchorlink[i].addEventListener('click', function () {
      const accordionlink = document.querySelectorAll('.material-nav__section a.is-active');
      for (let i = 0; i < accordionlink.length; i++) {
        accordionlink[i].click();//アクティブになってるボタンをクリックさせる→開いているアコーディオンを閉じる
      }
    });
	}
}

//「一括ダウンロード」ボタン領域の表示非表示
if (document.getElementsByClassName('material-bulk').length) {
  const materialBulk = document.getElementById('js--material-bulk');
  window.addEventListener('scroll', () => {
    const docHeight = document.body.clientHeight;
    const scrollCount = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollPos = document.documentElement.clientHeight + scrollCount;
    const footerHeight = document.querySelector('footer').clientHeight;
    if (scrollCount <= 100) {
      materialBulk.style.opacity = '0';
      materialBulk.style.visibility = 'hidden';
    } else {
      materialBulk.style.opacity = '1';
      materialBulk.style.visibility = 'visible';
    }
    // if (docHeight - scrollPos <= footerHeight) {
    //   pagetopBtn.style.position = 'absolute';
    //   pagetopBtn.style.top = 0;
    //   pagetopBtn.style.bottom = 'auto';
    //   pagetopBtn.style.transform = 'translateY(-50%)';
    // } else {
    //   pagetopBtn.style.position = 'fixed';
    //   pagetopBtn.style.top = 'auto';
    //   pagetopBtn.style.bottom = 20 + 'px';
    //   pagetopBtn.style.transform = 'none';
    // }
  });
}

if (document.getElementsByClassName('material').length) {
  //「一括ダウンロード」ボタン領域にチェックされたアイテム数を反映
  const material_count = document.getElementById("js--material-count");
  const material_download = document.getElementById("js--material-download");

  const material_body = document.getElementById("js--material-body");
  const material_check = material_body.querySelectorAll(`input[type="checkbox"]`);

  const material_service = document.getElementById('service');
  const material_service_check = material_service.querySelectorAll(`input[type="checkbox"]`);

  const material_magazine = document.getElementById('magazine');
  const material_magazine_check = material_magazine.querySelectorAll(`input[type="checkbox"]`);

  const material_report = document.getElementById('report');
  const material_report_check = material_report.querySelectorAll(`input[type="checkbox"]`);

  for (let i = 0; i < material_check.length; i++) {
    // material_check[i].addEventListener("click", getCheckedCount, false);
    material_check[i].addEventListener('click', function (e) {

      const material_check_category = e.target.id;//クリックしたチェックボックスのカテゴリ
      // console.log({material_check_category});
      const material_check_id = material_check_category.replace(/[^0-9]/g, '')//クリックしたチェックボックスのid
      // console.log({material_check_id});
        if (material_check[i].checked) {
          if ( material_check_category.indexOf('recommend') != -1) {//おすすめを選択
            if(document.getElementById("service"+material_check_id)){
              document.getElementById("service"+material_check_id).checked = true;
            }
            if(document.getElementById("magazine"+material_check_id)){
              document.getElementById("magazine"+material_check_id).checked = true;
            }
            if(document.getElementById("report"+material_check_id)){
              document.getElementById("report"+material_check_id).checked = true;
            }
          }else{//おすすめ以外を選択
            if(document.getElementById("recommend"+material_check_id)){
              document.getElementById("recommend"+material_check_id).checked = true;
            }
          }

        }else{
          if ( material_check_category.indexOf('recommend') != -1) {//おすすめを選択
            if(document.getElementById("service"+material_check_id)){
              document.getElementById("service"+material_check_id).checked = false;
            }
            if(document.getElementById("magazine"+material_check_id)){
              document.getElementById("magazine"+material_check_id).checked = false;
            }
            if(document.getElementById("report"+material_check_id)){
              document.getElementById("report"+material_check_id).checked = false;
            }
          }else{//おすすめ以外を選択
            if(document.getElementById("recommend"+material_check_id)){
              document.getElementById("recommend"+material_check_id).checked = false;
            }
          }
        }

        let count = 0;
        for (let i = 0; i < material_service_check.length; i++) {
          if (material_service_check[i].checked) {
              count++;
          }
        }
        for (let i = 0; i < material_magazine_check.length; i++) {
          if (material_magazine_check[i].checked) {
              count++;
          }
        }
        for (let i = 0; i < material_report_check.length; i++) {
          if (material_report_check[i].checked) {
              count++;
          }
        }

        // console.log({count});
        material_count.innerHTML = count;
        if(count > 0){
          material_download.classList.remove('-disable');
        }else{
          material_download.classList.add('-disable');
        }
        if(count > 5){
          material_check[i].checked = false;
          material_count.innerHTML = 5;

          if ( material_check_category.indexOf('recommend') != -1) {//おすすめを選択
            if(document.getElementById("service"+material_check_id)){
              document.getElementById("service"+material_check_id).checked = false;
            }
            if(document.getElementById("magazine"+material_check_id)){
              document.getElementById("magazine"+material_check_id).checked = false;
            }
            if(document.getElementById("report"+material_check_id)){
              document.getElementById("report"+material_check_id).checked = false;
            }
          }else{//おすすめ以外を選択
            if(document.getElementById("recommend"+material_check_id)){
              document.getElementById("recommend"+material_check_id).checked = false;
            }
          }

          //エラーモーダル出現
          MicroModal.show('modal_counterror', {
            disableScroll: true, // ページスクロールを無効に
            awaitOpenAnimation: true, // 開閉時のアニメーションを可能に
          });
        }

    });
  }

  //「もっと見る」ボタン押下時
  const moreBtn = document.getElementsByClassName('js-material-more');//"js-trigger"が付与された要素
  for (let i = 0; i < moreBtn.length; i++) {
    moreBtn[i].addEventListener('click', function () {

      const moreBtn_target = moreBtn[i].dataset.more;
      const moreBtn_section = document.getElementById(moreBtn_target);
      const moreBtn_item = [...moreBtn_section.getElementsByClassName('is-show is-hide')];
      const moreBtn_item_target = moreBtn_item.slice(0,6);
      for (let i = 0; i < moreBtn_item_target.length; i++) {
        moreBtn_item_target[i].classList.remove('is-hide');
      }
      if(moreBtn_item_target.length < 6){
        moreBtn[i].classList.add('is-hide');
      }
    });
  }

  //サービス > タグ押下時の処理
  const tag = document.getElementsByClassName('js-tag');//"js-tag"が付与された要素
  for (let i = 0; i < tag.length; i++) {
    tag[i].addEventListener('click', function () {

      const service_moreBtn = service.getElementsByClassName('js-material-more');
      for (let i = 0; i < service_moreBtn.length; i++) {
        service_moreBtn[i].classList.add('is-hide');
      }

      const section_name = tag[i].dataset.section;//クリックした要素のdate-trigger値
      const target_section = document.getElementById(section_name);
      const tag_name = tag[i].dataset.tag;//クリックした要素のdate-trigger値
      //const tag_class = tag[i].


      if(this.classList.contains('is-active')){//クリックしたタグが選択済みだった場合

        this.classList.remove('is-active');//選択解除
        const target_section_show = target_section.querySelectorAll('.material-item');
        for (let i = 0; i < target_section_show.length; i++) {
          target_section_show[i].classList.remove('is-hide');//該当セクション内の全アイテム表示
        }

      }else{

        //タグのアクティブ表示処理
        const target_section_tag = target_section.getElementsByClassName('js-tag');
        for (let i = 0; i < target_section_tag.length; i++) {
          target_section_tag[i].classList.remove('is-active');
        }
        const target_section_tag_target = target_section.querySelectorAll('[data-tag='+tag_name+']');
        for (let i = 0; i < target_section_tag_target.length; i++) {
          target_section_tag_target[i].classList.add('is-active');
        }

        //アイテムの表示非表示
        const target_section_show = target_section.querySelectorAll('.material-item:not(.is-hide)');
        for (let i = 0; i < target_section_show.length; i++) {
          target_section_show[i].classList.add('is-hide');
        }
        const target_section_item = target_section.querySelectorAll('.-'+tag_name);
        for (let i = 0; i < target_section_item.length; i++) {
          target_section_item[i].classList.remove('is-hide');
          target_section_item[i].classList.add('is-show');
        }

        if(tag_name == "popular"){//date-trigger値がtagの時
          const target_section_popular = target_section.querySelectorAll('[data-popular]');
          for (let i = 0; i < target_section_popular.length; i++) {
            target_section_popular[i].classList.remove('is-hide');
          }
        }

      }
    });
  }
}
